import React, { useCallback, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useIsReducedMotion } from '@atlaskit/motion';
import {
	Spotlight,
	SpotlightManager,
	SpotlightTarget,
	SpotlightTransition,
} from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';

import spotlightImage from './assets/DatabaseGAOnboarding.svg';

const TARGET_RADIUS = 4;
const DIALOG_WIDTH = 275;
const SCREEN_NAME = 'databaseGACreateSpotlight';

const EXPLORE_DATABASES_HREF = 'https://www.atlassian.com/software/confluence/databases';

const i18n = defineMessages({
	header: {
		id: 'databases-onboarding-ga.create.content.spotlight.header',
		defaultMessage: 'Organize and sync work',
		description: 'Header text inside the spotlight highlighting this new Database functionality',
	},
	body: {
		id: 'databases-onboarding-ga.create.content.spotlight.body',
		defaultMessage:
			'Databases organize, track, and sync your work across Confluence and Jira. <a>Explore Databases</a>',
		description: 'Body text inside the spotlight highlighting this new Database functionality',
	},
	dismissButton: {
		id: 'databases-onboarding-ga.create.content.spotlight.dismissButton',
		defaultMessage: 'Dismiss',
		description: 'Text on the button to click to indicate you are done viewing the spotlight',
	},
	tryButton: {
		id: 'databases-onboarding-ga.create.content.spotlight.tryButton',
		defaultMessage: 'Try databases',
		description: 'Text on the button to click to try the new Databases feature by creating one',
	},
});

// Backup outline in case we disable the spotlight pulse for a11y reasons
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpotlightTargetContainer = styled.div({
	borderRadius: '4px',
	boxShadow: `0 0 0 2px ${token('color.border.discovery')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled.a({
	textDecoration: 'underline',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.inverse')} !important`,
});

export interface CreateDatabaseGAPopoverItemSpotlightProps {
	children?: React.ReactNode;
	createHref?: string;
	dialogPlacement?: React.ComponentProps<typeof Spotlight>['dialogPlacement'];
	onClose?: () => void;
	source?: string;
	shouldWatchTarget?: boolean;
}

export const CreateDatabaseGAPopoverItemSpotlight = ({
	children,
	createHref,
	dialogPlacement,
	onClose,
	source,
	shouldWatchTarget = false,
}: CreateDatabaseGAPopoverItemSpotlightProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();
	const isReducedMotion = useIsReducedMotion();

	/**
	 * Make this `isSpotlightClosed` a reusable hook
	 * https://hello.jira.atlassian.cloud/browse/CTIA-2152
	 */
	const isSpotlightClosed = useRef<boolean>(false);
	const closeSpotlight = useCallback(() => {
		if (!isSpotlightClosed.current) {
			isSpotlightClosed.current = true;
			onClose?.();
		}
	}, [onClose]);

	useEffect(() => {
		return () => {
			closeSpotlight();
		};
	}, [closeSpotlight]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: SCREEN_NAME,
				attributes: { nudgeSource: source },
			},
		}).fire();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTryDatabases = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'tryIt',
				source: SCREEN_NAME,
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
		closeSpotlight();
	}, [createAnalyticsEvent, closeSpotlight, source]);

	const handleDismiss = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dismiss',
				source: SCREEN_NAME,
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
		closeSpotlight();
	}, [createAnalyticsEvent, closeSpotlight, source]);

	const handleLinkClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'exploreDatabases',
				source: SCREEN_NAME,
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, source]);

	return (
		<SpotlightManager onBlanketClicked={closeSpotlight}>
			<SpotlightTarget name="global-create-database-spotlight">
				<SpotlightTargetContainer>{children}</SpotlightTargetContainer>
			</SpotlightTarget>
			<SpotlightTransition>
				<Spotlight
					heading={intl.formatMessage(i18n.header)}
					target="global-create-database-spotlight"
					image={spotlightImage}
					targetBgColor={token('color.text.inverse')}
					pulse={!isReducedMotion}
					targetRadius={TARGET_RADIUS}
					dialogPlacement={dialogPlacement || 'right middle'}
					dialogWidth={DIALOG_WIDTH}
					shouldWatchTarget={shouldWatchTarget}
					actions={[
						{
							text: intl.formatMessage(i18n.tryButton),
							onClick: handleTryDatabases,
							href: createHref,
						},
						{
							text: intl.formatMessage(i18n.dismissButton),
							onClick: handleDismiss,
						},
					]}
				>
					<FormattedMessage
						{...i18n.body}
						values={{
							a: (chunks) => (
								<Link target="_blank" href={EXPLORE_DATABASES_HREF} onClick={handleLinkClick}>
									{chunks}
								</Link>
							),
						}}
					/>
				</Spotlight>
			</SpotlightTransition>
		</SpotlightManager>
	);
};
