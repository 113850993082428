import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

export const useWhiteboardNudgeExperiments = (experiment: string, experimentCohort: string) => {
	const getExperiment = (
		experimentName: string,
		cohort: string,
		shouldFireExperimentExposure: boolean,
	) => {
		// eslint-disable-next-line confluence-feature-gating/static-feature-gates
		const [experiment, fireExposure] = UNSAFE_noExposureExp(experimentName);
		shouldFireExperimentExposure && fireExposure();
		return experiment.get(cohort, 'not-enrolled');
	};

	const currentExperimentValue: string = getExperiment(experiment, experimentCohort, false);

	const getSkipCoordinationClient = (experimentValue: string) => {
		if (experimentValue === 'not-enrolled') {
			return true;
		}
		return false;
	};

	return {
		skipCoordinationClientForExperiment: getSkipCoordinationClient(currentExperimentValue),
		getExperiment,
	};
};
