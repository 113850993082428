import React, { useEffect, useRef, useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	Spotlight,
	SpotlightManager,
	SpotlightTarget,
	SpotlightTransition,
} from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';

import WhiteboardExperimentSpotlightImage from './assets/WhiteboardExperimentSpotlightImage.svg';

const i18n = defineMessages({
	tryWhiteboardsButton: {
		id: 'content-types-experiments.spotlight.try.whiteboards.button',
		defaultMessage: 'Try whiteboards',
		description: 'Text on the button to click to try whiteboards by creating one ',
	},
	dismissButton: {
		id: 'content-types-experiments.spotlight.dismiss.button',
		defaultMessage: 'Dismiss',
		description: 'Text on the button to click to indicate you are done viewing the spotlight',
	},
	body: {
		id: 'content-types-experiments.spotlight.body.text',
		defaultMessage: 'Collaborate on ideas and visualize work with whiteboards.',
		description: 'Body text inside the spotlight indicating the capabilities of whiteboards',
	},
});

const spotlightBodyContainer = xcss({
	height: '60px',
});

export interface CreateWhiteboardExperimentPopoverItemSpotlightProps {
	children?: React.ReactNode;
	createHref?: string;
	handleSpotlightClose?: () => void;
	handleClosePopoverMenu: (
		e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
	) => void;
	spotlightDialogPlacement: React.ComponentProps<typeof Spotlight>['dialogPlacement'];
	source: string;
	shouldWatchTarget?: boolean;
}

export const CreateWhiteboardExperimentPopoverItemSpotlight = ({
	children,
	createHref,
	handleSpotlightClose,
	handleClosePopoverMenu,
	spotlightDialogPlacement,
	source,
	shouldWatchTarget = false,
}: CreateWhiteboardExperimentPopoverItemSpotlightProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const spotlightRef = useRef(false);
	const close = useCallback(() => {
		if (!spotlightRef.current) {
			spotlightRef.current = true;
			handleSpotlightClose?.();
		}
	}, [handleSpotlightClose]);

	// Call `handleSpotlightClose()` when this component unmounts from global create, if it wasn't already closed in order to catch when the spotlight
	// disappears if users click outside of it. This is important because we need the parent to know when to call `stop()`
	// for the EP message so we don't block other onboarding from appearing for 5 min (the default expiration time)
	useEffect(() => {
		return () => {
			close();
		};
	}, [close, createAnalyticsEvent, source]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'whiteboardNudgeCampaignSpotlight',
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTryWhiteboards = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'link',
					actionSubjectId: 'tryIt',
					source: 'whiteboardNudgeCampaignSpotlight',
					attributes: {
						nudgeSource: source,
					},
				},
			}).fire();
			handleClosePopoverMenu?.(e);
			close();
		},
		[handleClosePopoverMenu, close, createAnalyticsEvent, source],
	);

	const handleDismiss = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dismiss',
				source: 'whiteboardNudgeCampaignSpotlight',
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
		close();
	}, [close, createAnalyticsEvent, source]);

	return (
		<SpotlightManager onBlanketClicked={close}>
			<SpotlightTarget name="create-whiteboard-experimentation-spotlight">
				{children}
			</SpotlightTarget>
			<SpotlightTransition>
				<Spotlight
					target="create-whiteboard-experimentation-spotlight"
					targetBgColor={token('color.text.inverse')}
					targetRadius={4}
					dialogPlacement={spotlightDialogPlacement}
					image={WhiteboardExperimentSpotlightImage}
					dialogWidth={275}
					shouldWatchTarget={shouldWatchTarget}
					actions={[
						{
							href: createHref,
							onClick: handleTryWhiteboards,
							text: intl.formatMessage(i18n.tryWhiteboardsButton),
						},
						{
							appearance: 'subtle',
							onClick: handleDismiss,
							text: intl.formatMessage(i18n.dismissButton),
						},
					]}
				>
					<Box xcss={spotlightBodyContainer}>
						<FormattedMessage {...i18n.body} />
					</Box>
				</Spotlight>
			</SpotlightTransition>
		</SpotlightManager>
	);
};
