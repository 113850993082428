import React, { useCallback, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useIsReducedMotion } from '@atlaskit/motion';
import {
	Spotlight,
	SpotlightManager,
	SpotlightTarget,
	SpotlightTransition,
} from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import spotlightImage from './assets/createSpotlightImage.png';

const i18n = defineMessages({
	body: {
		id: 'loom-onboarding.create.content.spotlight.body',
		defaultMessage: 'Explain the context for this page once with shareable, async video.',
		description: 'Body text inside the spotlight highlighting this new Loom functionality',
	},
	globalCreateBody: {
		id: 'loom-onboarding.create.content.globalCreate.spotlight.body',
		defaultMessage: 'Record a walkthrough of this page to share updates and progress.',
		description: 'Body text inside the spotlight highlighting this new Loom functionality',
	},
	dismissButton: {
		id: 'loom-onboarding.create.content.spotlight.dismissButton',
		defaultMessage: 'Dismiss',
		description: 'Text on the button to click to indicate you are done viewing the spotlight',
	},
	recordButton: {
		id: 'loom-onboarding.create.content.spotlight.recordButton',
		defaultMessage: 'Record',
		description: 'Text on the button to click to start recording a Loom video',
	},
});

// Backup outline in case we disable the spotlight pulse for a11y reasons
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const SpotlightTargetContainer = styled.div({
	borderRadius: '6px',
	boxShadow: `0 0 0 2px ${token('color.border.discovery')}`,
});

const GA_SCREEN_NAME = 'recordLoomGAPopoverItemSpotlight';

export interface RecordLoomPopoverItemSpotlightProps {
	/**
	 * spotlight trigger component.
	 */
	children?: React.ReactNode;
	/**
	 * spotlight "Try it out" button callback.
	 */
	onRecord?: () => void;
	/**
	 * spotlight dismiss button callback.
	 */
	onClose?: () => void;
	/**
	 * analytics source attribute
	 */
	source?: string;

	/**
	 * Set this to be true for using spotlight in popup items
	 */
	shouldWatchTarget?: boolean;
}

export const RecordLoomPopoverItemSpotlight = ({
	children,
	onRecord,
	onClose,
	source,
	shouldWatchTarget,
}: RecordLoomPopoverItemSpotlightProps) => {
	const isReducedMotion = useIsReducedMotion();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const spotlightTargetRef = useRef<HTMLDivElement | null>(null);
	const isClosed = useRef<boolean>(false);

	const close = useCallback(() => {
		if (!isClosed.current) {
			isClosed.current = true;
			onClose?.();
		}
	}, [onClose]);

	// Call `onClose()` when this component unmounts if it wasn't already closed in order to catch when the spotlight
	// disappears if users click outside of it. This is important because we need the parent to know when to call `stop()`
	// for the EP message so we don't block other onboarding from appearing for 5 min (the default expiration time)
	useEffect(() => {
		return () => {
			close();
		};
	}, [close]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: GA_SCREEN_NAME,
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDismiss = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dismiss',
				source: GA_SCREEN_NAME,
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
		close();
	}, [close, createAnalyticsEvent, source]);

	const handleTryIt = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'record',
				source: GA_SCREEN_NAME,
				attributes: {
					nudgeSource: source,
				},
			},
		}).fire();
		onRecord?.();
		close();
	}, [onRecord, close, createAnalyticsEvent, source]);

	return (
		<SpotlightManager onBlanketClicked={handleDismiss}>
			<SpotlightTarget name="create-menu-item-loom-spotlight">
				<SpotlightTargetContainer ref={spotlightTargetRef}>{children}</SpotlightTargetContainer>
			</SpotlightTarget>
			<SpotlightTransition>
				<Spotlight
					target="create-menu-item-loom-spotlight"
					targetBgColor={token('color.text.inverse')}
					pulse={!isReducedMotion}
					targetRadius={4}
					dialogPlacement="right bottom"
					dialogWidth={275}
					shouldWatchTarget={shouldWatchTarget}
					image={spotlightImage}
					actions={[
						{
							onClick: handleTryIt,
							text: intl.formatMessage(i18n.recordButton),
						},
						{
							onClick: handleDismiss,
							text: intl.formatMessage(i18n.dismissButton),
						},
					]}
				>
					{source == 'globalCreateDropdown-smartLinkEmbed' ? (
						<FormattedMessage {...i18n.globalCreateBody} />
					) : (
						<FormattedMessage {...i18n.body} />
					)}
				</Spotlight>
			</SpotlightTransition>
		</SpotlightManager>
	);
};
