import type { ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';

import type { MessageDeliveryStatuses } from '@atlassian/ipm-choreographer';

import { useChoreographerAssets } from '@confluence/choreographer-services';

import LiveDocsIntroGif from './assets/LiveDocsIntroGif.gif';

export type CreateLiveDocPopoverSpotlightProps = {
	children: ReactNode;
	handleDismiss: () => void;
	handleProceed: (
		e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
	) => void | undefined;
	targetName: string;
	source: string;
	fireAnalyticsEventOnMessageDisposition?: boolean;
};

export const CreateLiveDocPopoverItemSpotlight = ({
	children,
	handleDismiss,
	handleProceed,
	targetName,
	source,
	fireAnalyticsEventOnMessageDisposition = true,
}: CreateLiveDocPopoverSpotlightProps) => {
	const { Spotlight } = useChoreographerAssets();
	const { formatMessage } = useIntl();

	const sourceComponent = source.toLowerCase();
	const isContextual = sourceComponent.includes('contextual');
	const isGlobalCreate = sourceComponent.includes('global');
	const dialogPlacement = useMemo(() => {
		if (isContextual) {
			return 'right middle';
		} else if (isGlobalCreate) {
			return 'left top';
		}
		return undefined;
	}, [isContextual, isGlobalCreate]);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onMessageDisposition = useCallback(
		(disposition: MessageDeliveryStatuses) => {
			if (disposition === 'started') {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'shown',
						actionSubject: 'spotlight',
						actionSubjectId: 'liveDocIntro',
						source: sourceComponent,
					},
				}).fire();
			}
		},
		[createAnalyticsEvent, sourceComponent],
	);

	return (
		<SpotlightManager>
			<SpotlightTarget name={targetName}>{children}</SpotlightTarget>
			<SpotlightTransition>
				<Spotlight
					dialogPlacement={dialogPlacement}
					actions={[
						{
							onClick: (e) => {
								createAnalyticsEvent({
									type: 'sendUIEvent',
									data: {
										action: 'clicked',
										actionSubject: 'button',
										actionSubjectId: 'tryItOut',
										source: 'liveDocIntroSpotlight',
										attributes: {
											spotlightSource: sourceComponent,
										},
									},
								}).fire();
								handleProceed(e);
							},

							text: formatMessage(introSpotlight.tryItOut),
						},
						{
							onClick: () => {
								createAnalyticsEvent({
									type: 'sendUIEvent',
									data: {
										action: 'clicked',
										actionSubject: 'button',
										actionSubjectId: 'dismiss',
										source: 'liveDocIntroSpotlight',
										attributes: {
											spotlightSource: sourceComponent,
										},
									},
								}).fire();
								handleDismiss();
							},
							text: formatMessage(introSpotlight.dismiss),
						},
					]}
					heading={formatMessage(introSpotlight.heading)}
					targetRadius={6}
					target={targetName}
					key={`${targetName}-key`}
					messageType="engagement"
					messageId={`${targetName}-message-id`}
					image={LiveDocsIntroGif}
					dialogWidth={338}
					targetOnClick={(eventData) => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'tryItOut',
								source: 'onboardingLiveDocIntroSpotlight',
								attributes: {
									menu: sourceComponent,
								},
							},
						}).fire();
						handleProceed(eventData.event);
					}}
					//Node: Both `scrollPositionBlock` and `shouldWatchTarget` are needed to make sure the spotlight positioned correctly in contextual create flow of page tree
					//There is known bug logged for the shifting issue from platform component: https://product-fabric.atlassian.net/browse/DSP-18777
					shouldWatchTarget
					scrollPositionBlock={isContextual ? 'nearest' : undefined}
					// TODO: this prop has caused the useLiveDocItem in universal create not showing the spotlight promptly, which always shows in the next react update.
					// Disabling it helped to resolve the issue.
					{...(fireAnalyticsEventOnMessageDisposition ? { onMessageDisposition } : {})}
					targetBgColor={token('elevation.surface.overlay')}
				>
					{formatMessage(introSpotlight.body)}
				</Spotlight>
			</SpotlightTransition>
		</SpotlightManager>
	);
};

const introSpotlight = defineMessages({
	heading: {
		id: 'onboarding-live-doc-changeboarding.introSpotlight.heading',
		defaultMessage: 'Start collaborating with Live Docs',
		description:
			'Title of a tutorial message describing the Live Docs option in a create content menu.',
	},
	body: {
		id: 'onboarding-live-doc-changeboarding.introSpotlight.body',
		defaultMessage:
			'Use live docs for quick, messy brainstorming and collaboration with your team.',
		description:
			'Body of a tutorial message describing the Live Docs option in a create content menu.',
	},
	dismiss: {
		id: 'onboarding-live-doc-changeboarding.introSpotlight.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Label for a button to dismiss the onboarding tutorial',
	},
	tryItOut: {
		id: 'onboarding-live-doc-changeboarding.introSpotlight.tryItOut',
		defaultMessage: 'Try it out',
		description: 'Label for the button to do what the tutorial step suggests.',
	},
});
