/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import React, { useEffect, useContext } from 'react';
import type { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { WIKI } from '@confluence/named-routes';
import { WhiteboardsActiveBoardLimitModal } from '@confluence/change-edition/entry-points/WhiteboardsActiveBoardLimitModal';
import { RoutesContext } from '@confluence/route-manager';
import { ErrorDisplay } from '@confluence/error-boundary';
import {
	WHITEBOARD_CREATION_PAYWALL_SOURCE_PREFIX,
	WHITEBOARD_PAYWALL_UPSELL_ACTION_SUBJECT_ID,
	WHITEBOARD_PAYWALL_INFO_ACTION_SUBJECT_ID,
} from '@confluence/whiteboard-utils';
import {
	CONTENT_CREATION_PAYWALL_EXPERIENCE,
	ExperienceFailure,
} from '@confluence/experience-tracker';

import type { WhiteboardCreationLinkOptions } from '../../CreateContentRouteComponent';
import { PaywallStatus } from '../../createContentInputTypes';

import type { WhiteboardCreationPaywallDialogWhiteboardCreationPaywallContentToDisableQuery as WhiteboardCreationPaywallDialogWhiteboardCreationPaywallContentToDisableQuery$data } from './__types__/WhiteboardCreationPaywallDialogWhiteboardCreationPaywallContentToDisableQuery';

const SCREEN_NAME = `${WHITEBOARD_CREATION_PAYWALL_SOURCE_PREFIX}upfrontDialog`;

const i18n = defineMessages({
	body: {
		id: 'create-blank-fabric-page.whiteboard-creation-paywall-dialog.body',
		defaultMessage:
			'You have a limit of three editable whiteboards. If you create a new whiteboard, an older whiteboard will become view-only.',
		description:
			'Text in the creation paywall modal describing the editable whiteboard limit and what will happen if you create a new whiteboard',
	},
	bodyUpsell: {
		id: 'create-blank-fabric-page.whiteboard-creation-paywall-dialog.bodyUpsell',
		defaultMessage: 'To create unlimited whiteboards, upgrade to Premium.',
		description:
			'Text in the creation paywall modal describing how to bypass the whiteboard creation limit',
	},
	createWhiteboard: {
		id: 'create-blank-fabric-page.whiteboard-creation-paywall-dialog.createWhiteboard',
		defaultMessage: 'Create whiteboard',
		description: 'Text for a link that will proceed to create a new whiteboard',
	},
	defaultTitle: {
		id: 'create-blank-fabric-page.whiteboard-creation-paywall-dialog.defaultWhiteboardName',
		defaultMessage: 'An older board will become view-only',
		description:
			'A modal dialog title explaining that an older whiteboard could become view-only based on actions taken within the modal',
	},
	learnMore: {
		id: 'create-blank-fabric-page.whiteboard-creation-paywall-dialog.learnMore',
		defaultMessage: 'Learn about usage',
		description: 'Text for a link that leads to documentation about whiteboard usage limits',
	},
	title: {
		id: 'create-blank-fabric-page.whiteboard-creation-paywall-dialog.title',
		defaultMessage: '{whiteboardName} will become view-only',
		description:
			'A modal dialog title explaining which whiteboard could become view-only based on actions taken within the modal',
	},
});

type DialogTitleProps = {
	href?: string | null;
	name?: string | null;
};

const DialogTitle = ({ href, name }: DialogTitleProps) => {
	const getTitleText = () => {
		if (!name) {
			return <FormattedMessage {...i18n.defaultTitle} tagName="span" />;
		}

		const whiteboardName = href ? (
			// eslint-disable-next-line @atlaskit/design-system/no-html-anchor
			<a href={`${WIKI.toUrl()}${href}`} target="_blank">
				{name}
			</a>
		) : (
			name
		);
		return <FormattedMessage {...i18n.title} values={{ whiteboardName }} />;
	};

	return <span data-testid="paywall-title">{getTitleText()}</span>;
};

export type WhiteboardCreationPaywallDialogProps = {
	onClose: () => void;
	onCloseComplete: () => void;
	buildWhiteboardCreationLink: (
		creationOverrides?: Partial<WhiteboardCreationLinkOptions>,
		sourceSuffix?: string,
	) => string;
};

export const WhiteboardCreationPaywallDialog: FC<WhiteboardCreationPaywallDialogProps> = ({
	onClose,
	onCloseComplete,
	buildWhiteboardCreationLink,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useContext(RoutesContext);
	const { data, loading, error } =
		useQuery<WhiteboardCreationPaywallDialogWhiteboardCreationPaywallContentToDisableQuery$data>(
			gql`
				query WhiteboardCreationPaywallDialogWhiteboardCreationPaywallContentToDisableQuery {
					paywallContentToDisable(contentType: "whiteboard") {
						deactivationIdentifier
						link
						title
					}
				}
			`,
			{
				fetchPolicy: 'cache-and-network',
			},
		);
	const { link, title, deactivationIdentifier } = data?.paywallContentToDisable || {};

	const creationHref = buildWhiteboardCreationLink(
		{
			deactivationIdentifier,
			paywallStatus: PaywallStatus.ACTIVE,
		},
		'-creationPaywall',
	);

	useEffect(() => {
		// if the query has finished and there is no deactivationIdentifier, then we opened this modal due to caching issues and should proceed directly to creation
		if (!loading && !error && !deactivationIdentifier) {
			push(creationHref);
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'autoCreate',
					actionSubject: 'activeSlotAvailableAfterRenderingCreationPaywall',
					source: SCREEN_NAME,
				},
			}).fire();

			onClose();
			onCloseComplete();
		}
	}, [
		deactivationIdentifier,
		loading,
		error,
		creationHref,
		onClose,
		onCloseComplete,
		push,
		createAnalyticsEvent,
	]);

	const creationLink = {
		actionSubjectId: 'whiteboardPaywallCreateNewWhiteboardButton',
		messageDescriptor: i18n.createWhiteboard,
		href: creationHref,
		onClick: () => {
			onClose();
			onCloseComplete();
		},
		target: '_self',
	};

	const learnMoreLink = {
		actionSubjectId: WHITEBOARD_PAYWALL_INFO_ACTION_SUBJECT_ID,
		href: 'https://support.atlassian.com/confluence-cloud/docs/understand-confluence-whiteboards-plans-and-features/',
		messageDescriptor: i18n.learnMore,
	};

	return (
		<WhiteboardsActiveBoardLimitModal
			learnMoreLink={learnMoreLink}
			onClose={onClose}
			onCloseComplete={onCloseComplete}
			secondaryLink={creationLink}
			source={SCREEN_NAME}
			title={<DialogTitle href={link} name={title} />}
			upgradeEditionActionSubjectId={WHITEBOARD_PAYWALL_UPSELL_ACTION_SUBJECT_ID}
		>
			<FormattedMessage {...i18n.body} tagName="p" />
			<FormattedMessage {...i18n.bodyUpsell} tagName="p" />
			{error && (
				<ErrorDisplay error={error}>
					<ExperienceFailure name={CONTENT_CREATION_PAYWALL_EXPERIENCE} error={error} />
				</ErrorDisplay>
			)}
		</WhiteboardsActiveBoardLimitModal>
	);
};
