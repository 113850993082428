const LOOM_URL_REGEX =
	/^https:\/\/(.*?\.)?(loom\..*?\/(share|embed))\/([a-zA-Z0-9-]*-)?(?<videoId>[a-f0-9]{32})/;

function extractLoomVideoId(url: string) {
	const match = url.match(LOOM_URL_REGEX);
	return match?.groups?.videoId;
}

async function digestMessage(message: string) {
	// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
	if (window?.crypto?.subtle?.digest) {
		const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
		const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8); // hash the message
		const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
		const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
		return hashHex;
	}
	return '';
}

export async function getHashedLoomVideoIdFromUrl(url: string): Promise<string> {
	try {
		const videoId = extractLoomVideoId(url);
		if (videoId) {
			const data =
				atob('c2U1UTwgekZlVEYkP1MzRno1QmV5WXBoSE13SHg8RWpjM10saHJQZmhPMVVHKHM=') + videoId;
			const digestHex = await digestMessage(data);
			return digestHex ? `vidhash-${digestHex}` : '';
		}
		return '';
	} catch (_) {
		return '';
	}
}
