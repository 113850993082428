/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { useSessionData } from '@confluence/session-data';
import { CREATE_WHITEBOARD_EXPERIENCE } from '@confluence/experience-tracker';
import type { ExperienceStart } from '@confluence/experience-tracker';
import { useIsAllowedToCreateWhiteboard } from '@confluence/content-types-utils';

import { PaywallStatus } from '../../createContentInputTypes';

import type { useWhiteboardCreationPaywallWhiteboardCreationPaywallContentToDisableQuery as useWhiteboardCreationPaywallWhiteboardCreationPaywallContentToDisableQuery$data } from './__types__/useWhiteboardCreationPaywallWhiteboardCreationPaywallContentToDisableQuery';

type UseWhiteboardCreationPaywallResponse = {
	deactivationIdentifier?: string;
	paywallStatus?: PaywallStatus;
	error?: ApolloError;
	showPaywallBeforeCreate: boolean;
};

export const useWhiteboardCreationPaywall = (): UseWhiteboardCreationPaywallResponse => {
	const { edition } = useSessionData();
	const isPremium = edition === ConfluenceEdition.PREMIUM;
	const isAllowedToCreateWhiteboard = useIsAllowedToCreateWhiteboard();

	const shouldSkipQuery = !isAllowedToCreateWhiteboard || isPremium;
	const { data, error } =
		useQuery<useWhiteboardCreationPaywallWhiteboardCreationPaywallContentToDisableQuery$data>(
			gql`
				query useWhiteboardCreationPaywallWhiteboardCreationPaywallContentToDisableQuery {
					paywallContentToDisable(contentType: "whiteboard") {
						deactivationIdentifier
						link
						title
					}
				}
			`,
			// "cache-and-network" reduces caching issues resulting in the paywall modal showing when it shouldn't
			{
				fetchPolicy: 'cache-and-network',
				skip: shouldSkipQuery,
			},
		);
	if (isPremium) {
		return {
			deactivationIdentifier: undefined,
			paywallStatus: PaywallStatus.ACTIVE,
			showPaywallBeforeCreate: false,
		};
	}

	const deactivationIdentifier = data?.paywallContentToDisable?.deactivationIdentifier;
	return {
		deactivationIdentifier,
		error,
		paywallStatus: data ? PaywallStatus.ACTIVE : PaywallStatus.UNSET,
		showPaywallBeforeCreate: Boolean(deactivationIdentifier),
	};
};

type CollectExperienceParams = React.ComponentProps<typeof ExperienceStart>['collect'];

export const collectStartCreateWhiteboardExperience: CollectExperienceParams = (
	events,
	experience,
) => {
	const whiteboardExperienceStart = events.find(
		(event) => event.action === 'taskStart' && event.name === CREATE_WHITEBOARD_EXPERIENCE,
	);
	if (whiteboardExperienceStart) {
		experience.succeed();
	}
};
