/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ConfluencePageSubType {
  LIVE = "LIVE",
}

export enum GraphQLContentStatus {
  ARCHIVED = "ARCHIVED",
  CURRENT = "CURRENT",
  DELETED = "DELETED",
  DRAFT = "DRAFT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBlankPageMutation
// ====================================================

export interface CreateBlankPageMutation_createContentContextual_space {
  id: string | null;
}

export interface CreateBlankPageMutation_createContentContextual_links {
  editui: string | null;
}

export interface CreateBlankPageMutation_createContentContextual_metadata_frontend {
  collabService: string | null;
}

export interface CreateBlankPageMutation_createContentContextual_metadata {
  frontend: CreateBlankPageMutation_createContentContextual_metadata_frontend | null;
}

export interface CreateBlankPageMutation_createContentContextual {
  id: string | null;
  space: CreateBlankPageMutation_createContentContextual_space | null;
  links: CreateBlankPageMutation_createContentContextual_links | null;
  metadata: CreateBlankPageMutation_createContentContextual_metadata;
  subType: string | null;
}

export interface CreateBlankPageMutation {
  createContentContextual: CreateBlankPageMutation_createContentContextual;
}

export interface CreateBlankPageMutationVariables {
  subType?: ConfluencePageSubType | null;
  spaceKey: string;
  parentId?: string | null;
  title?: string | null;
  status: GraphQLContentStatus;
}
