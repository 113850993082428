import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';

const contentTitleStyles = xcss({
	fontWeight: 'font.weight.medium',
	color: 'color.text',
	marginRight: 'space.075',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

export const ContentTitle = ({ children }) => <Box xcss={contentTitleStyles}>{children}</Box>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DisabledContentTitle = styled.div({
	fontWeight: 'font.weight.medium',
	color: token('color.text.disabled'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LozengeWrapper = styled.div({
	display: 'flex',
});

const nonStandardIconSize = '18px !important';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentIconWrapper = styled.div({
	display: 'flex',
	flexShrink: '0',
	justifyContent: 'center',
	width: token('space.300'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'svg, span': {
		height: nonStandardIconSize,
		width: nonStandardIconSize,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MenuItemWrapper = styled.div<{ isDisabled?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`a[data-testid="create-menu-link-item"]`]: {
		minHeight: 'unset',
		height: token('space.500'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`button[data-testid="create-menu-button-item"]`]: {
		minHeight: 'unset',
		height: token('space.500'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`div[data-testid="skeleton-create-menu-button-item"]`]: {
		padding: `${token('space.0')} ${token('space.200')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.isDisabled ? '50%' : '100%'),
});
