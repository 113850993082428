import type { FC, ReactNode } from 'react';
import React, { useCallback } from 'react';

import type { ButtonItemProps } from '@atlaskit/menu';
import { ButtonItem } from '@atlaskit/menu';
import Spinner from '@atlaskit/spinner';
import VisuallyHidden from '@atlaskit/visually-hidden';

export type LoadingPopoverItemProps = {
	children: ReactNode;
	isLoading?: boolean;
	loadingLabel: string;
	onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
} & ButtonItemProps;

export const LoadingPopoverItem: FC<LoadingPopoverItemProps> = ({
	children,
	isLoading,
	loadingLabel,
	onClick,
	...props
}) => {
	const handleClick = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent) => {
			if (isLoading) {
				return;
			}
			onClick?.(e);
		},
		[isLoading, onClick],
	);

	const loadingProps = {
		'aria-disabled': true,
		'aria-label': loadingLabel,
		iconAfter: <Spinner size="small" />,
	};

	return (
		<>
			<VisuallyHidden role="status">{isLoading ? loadingLabel : undefined}</VisuallyHidden>
			<ButtonItem onClick={handleClick} {...props} {...(isLoading && loadingProps)}>
				{children}
			</ButtonItem>
		</>
	);
};
