import { useCallback, useContext } from 'react';
import type { MouseEvent, KeyboardEvent } from 'react';

import { getSpaTransitionHref, RoutesContext } from '@confluence/route-manager';

// because we have nested links in pageTreeLinkItem, we cant rely solely on the href (both links get triggered if you clicked the nested one)
//  stopPropagation() (called in a different function) stops the href from doing a SPA transition because it doesnt propogate to the GlobalAnchorHandler
//  if it is going to be a SPA, then push instead
//  if it is not going to be a SPA (opening in a new tab) then we can allow the href to do the full page transition (by not preventing default)

export const useHybridLinkClick = (href?: string) => {
	const routesContext = useContext(RoutesContext);

	return useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			const isSpaTransition = !!getSpaTransitionHref(
				event as MouseEvent<HTMLAnchorElement>,
				routesContext,
			);

			if (isSpaTransition && href) {
				event.preventDefault();
				routesContext.push(href);
			}
		},
		[routesContext, href],
	);
};
